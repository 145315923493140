import React from "react";
import PDF from "../docs/OnTheOne-SongSplitSheetTemplate.pdf";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form onSubmit={this.submitForm} action="https://formspree.io/f/xwkwzwdo" method="POST">
        <input type="text" name="First Name" placeholder="First name" required />{" "}
        <input type="text" name="Last Name" placeholder="Last name" required />
        <select name="Profession Industry" id="professionIndustry" required>
          <option value="" disabled selected>
            Profession Industry...
          </option>
          <option value="Music Educator">Music Educator</option>
          <option value="Worship Pastor">Worship Pastor</option>
          <option value="Choir Director">Choir Director</option>
          <option value="Music Director">Music Director</option>
          <option value="Musician">Musician</option>
          <option value="Instrumentalist">Instrumentalist</option>
          <option value="Spoken Word">Spoken Word</option>
          <option value="Singer">Singer</option>
          <option value="Song Writer">Song Writer</option>
          <option value="Producer">Producer</option>
          <option value="Promoter">Promoter</option>
          <option value="Clergy">Clergy</option>
          <option value="Other">Other</option>
        </select>
        <input type="email" name="Email" placeholder="Email" required />
        <textarea type="text" rows="6" name="Message" placeholder="Message" required />
        {status === "SUCCESS" ? (
          <p>
            Thank you for your message!
            <br />
            Download{" "}
            <a href={PDF} download>
              OnTheOne - Song Split Sheet Template
            </a>
          </p>
        ) : (
          <button>Send</button>
        )}
        {status === "ERROR" && <p>Oops! There was an error.</p>}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
