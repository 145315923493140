import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  @media screen and (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`

export const ContactContainer = styled.div`
  width: 500px;
  height: 100%;
  max-height: 85vh;
  overflow: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 40px 5px 10px 5px;
  background: #fff;
  color: #000;

  @media screen and (max-width: 1024px) {
    max-width: 90vw;
    padding: 30px 5px 5px 5px;
  }
`

export const ContactWrapper = styled.div`
  margin: 0 auto;
  padding: 20px 0;
  width: 75%;

  @media screen and (max-width: 480px) {
    max-width: 90%;
    padding: 10px 0;
  }

  h1 {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 42px;

    @media screen and (max-width: 480px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 28px;
    }
  }

  p {
    margin-bottom: 25px;
  }

  input,
  textarea,
  select {
    width: 100%;
    padding: 10px 10px;
    /* border-radius: 5px; */
    margin-bottom: 20px;
    max-height: 150px;
    max-width: 380px;

    :focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border: 1px solid #000;
      outline: 0;
    }
  }

  button {
    color: #fff;
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 4px;
    border: 2px solid #fff;
    background-color: #000;
    padding: 10px 40px;
    cursor: pointer;
    margin-bottom: 30px;

    :hover {
      background-color: #1e1e27;
      transition: all 0.3s ease-in-out;
    }
  }
`

export const CloseButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
`
