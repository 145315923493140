import React from 'react'
import { Nav, NavContainer, NavLogo } from './NavElements'
import Logo from '../../images/OntheOne-logo.png'

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavContainer>
          <NavLogo to='/'>
            <img
              src={Logo}
              alt='One the One'
            />
          </NavLogo>
        </NavContainer>
      </Nav>
    </>
  )
}

export default Navbar
