import React, { useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import ContactForm from '../ContactForm'
import {
  Background,
  ContactContainer,
  ContactWrapper,
  CloseButton
} from './ContactModalElements'

const ContactModal = ({ showModal, setShowModal }) => {
  const modalRef = useRef()

  const slideDown = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  })

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={slideDown}>
            <ContactContainer>
              <ContactWrapper>
                <h1>Be in the know</h1>
                <ContactForm />
              </ContactWrapper>
              <CloseButton onClick={() => setShowModal(show => !show)} />
            </ContactContainer>
          </animated.div>
        </Background>
      ) : null}
    </>
  )
}

export default ContactModal
