import React, { useState } from 'react'
import {
  HeroBg,
  HeroContainer,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  Button
} from './HeroElements'
import ContactModal from '../ContactFormModal'
import Video from '../../videos/Microphone.mp4'
import LogoTitle from '../../images/logo-title.png'

const HeroSection = () => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(show => !show)
  }

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          Welcome to <img src={LogoTitle} />
        </HeroH1>
        <HeroP>
          Serving as a digital platform within the Christian and Gospel Music
          industry in Canada, we aim to provide information, support and
          resources which will create a strong and healthy relationship between
          artists, promoters and creators with venues inclusive of the Houses of
          Worship (Church).
        </HeroP>
        <Button onClick={openModal}>Be in the know</Button>
        <ContactModal showModal={showModal} setShowModal={setShowModal} />
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
