import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
  background: transparent;
  width: 100%;
  height: 90px;
  margin-top: -90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 2;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
`

export const NavLogo = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  animation: slideDown 1s ease-out 0s 1;

  img {
    max-width: 90px;
  }
`
