import styled from 'styled-components'

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* margin-top: -80px; */
  position: relative;
`

export const HeroBg = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

export const HeroContent = styled.div`
  z-index: 2;
  position: absolute;
  max-width: 660px;
  padding: 20px;
  animation: slideUp 1s ease-out 0s 1;
`

export const HeroH1 = styled.h1`
  margin-bottom: 15px;
  font-size: 40px;
  line-height: 2.7rem;

  /* span {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 48px;
  } */

  img {
    max-width: 340px;
    vertical-align: text-bottom;

    @media screen and (max-width: 480px) {
      max-width: 310px;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 300px;
  }
`

export const HeroP = styled.p`
  line-height: 1.4;
`

export const Button = styled.button`
  cursor: pointer;
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 25px;
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;
  padding: 15px 30px;
  letter-spacing: 4px;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: #000;
    color: #fff;
    border: 2px solid #000;
  }
`
